/* fancybox */
/* @import "https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.1.25/jquery.fancybox.min.css"; */
/* @import "node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css"; */


.fancybox-wrap, .fancybox-wrap *{
    -moz-box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;
    -safari-box-sizing: content-box !important;
    box-sizing: content-box !important;
}

a.fancybox.thumb {
    position: relative;
    display: inline-block;
    &:before {
        content: "\f00e";
        z-index: 5;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate( -50%, -50% );
        padding: 3px 15px 3px 25px;
        color: white;
        font-family: 'FontAwesome';
        font-size: 50px !important;
        background-color: rgba(23, 35, 34, 0.75);
        border-radius: 5px 5px 5px 5px;
    }
}
