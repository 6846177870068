.classic-tabs {
    margin-top: -70px;
    @extend .card;
    z-index: 100;
    img {
        @extend .img-fluid;
    }
    .nav {
        &.tabs-bilaffaren {
            background-color: $primary-color;
        }
        > li {
            @media (min-width: 62em) {
                &:first-child {
                    margin-left: 0;
                }
            }
            a {
                color: white;
                border-bottom: 0;
                &.active {
                    color: $primary-color;
                    background-color: white;
                    border-color: white;
                }
            }
        }
    }
    h3, h4 {
        @extend .title;
    }
    h3 {
        @extend .h2-responsive;
    }
    h4 {
        margin-bottom: 20px;
        @extend .h4-responsive;
    }
    .tab-content {
        .tab-pane {
            .row {
                > div {
                    padding: 15px;
                }
            }
        }
    }
}
