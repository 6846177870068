/* trygghet */
.trygghet {
    @extend .pb-3;
    background-color: $grey-darken-3;
    .row {
        @extend .container;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
    }
}
.reco {
  color: black;
  @extend .py-3;
  background-color: white;
  .row {
      @extend .container;
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
  }
}
