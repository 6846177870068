/* billistan */
body {
    section#bilar {
        .carcontainer {
            max-width: 100%;
            a {
                @extend .z-depth-1;
                &:hover {
                    @extend .z-depth-2;
                }
                .carimage {
    
                }
                .carinfo {
                    .header {
                        @extend h5;
                        font-size: 1.3em;
                        font-weight: 400;
                        overflow: hidden;
                        background-color: black;
                        margin: -10px;
                        padding: 10px 5px;
                        max-height: 50px;
                        text-overflow: ellipsis;
                        white-space: nowrap!important;
                        text-align: center;
                    }
                    .info {
                        @include make-row();
                        color: grey;
                        padding-top: 15px;
                        .facts {
                            @include make-col-ready();
                            @include make-col(6);
                            padding: 0 10px;
                            .dl-horizontal {
                                margin-bottom: 0;
                                width: 100%;
                            }
                        }
                        .price {
                            @include make-col-ready();
                            @include make-col(6);
                            position: relative;
                            border: 0;
                            padding: 0;
                            text-align: right;
                            font-size: 100%;
                            > div {
                                position: absolute;
                                bottom:0;
                                right:10;
                            }
                            span {
                                @extend h1;
                                font-size: 1.4em;
                                font-weight: 400;
                                color: $grey-darken-3;
    
                            }
                            i.fa-info-circle, i.fa-arrow-down {
                                margin-left: 0;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        /* filtered */
        .cars {
            .grid {
                .mix {
                    a.carcontainer {
                        color: black;
                        display: block;
                        .carimage {
                            position: relative;
                            overflow: hidden;
                            .carplace {
                                @extend .z-depth-1;
                                background: $brand-color;
                                color: rgb(255, 255, 255);
                                padding: 0;
                                text-align: center;
                                font-weight: 600;
                                position: absolute;
                                text-transform: uppercase;
                                font-weight: bold;
                                width: 220px;
                                -webkit-transform: rotate(-45deg);
                                -moz-transform: rotate(-45deg);
                                -ms-transform: rotate(-45deg);
                                -o-transform: rotate(-45deg);
                                transform: rotate(-45deg);
                                top: 35px;
                                left: -65px;
                                &.haninge {
                                    background: $grey-darken-3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
