// Your custom styles

body {
    color: #646464;
    font-weight: 400;
    background-color: #f4f4f4;
    img {
        @extend .img-fluid;
    }
    a {
        color: $brand-color;
    }
    h1, h2, h3, h4, h5 {
        @extend .text-uppercase;
        font-weight: 700;
    }
    h2 {color:#292a24;}
    main {
        
        .container {
            @extend .py-5;
            .content {
                @extend .py-2;
            }
        }
        h2:first-of-type {
            @extend .h1-responsive;
            margin-bottom: 35px;
        }
    }
}
/* fontawesome */
.md-form.md-outline .prefix, form .md-outline.form-group .prefix {
    width: 25px;
    text-align: center;
    left: -5px;
}

.btn .fa {
    margin-top: -4px;
}

@media print {
    .container {
        width: auto;
    }
}
.bigbuttons {
    a.btn {
        font-size: 1rem;
        i.fa {
            margin-top: -8px;
            margin-right: 1rem;
            font-size: 2rem;
        }
    }
}
.fp-cards {
    @extend .container;
    .row {
        @extend .mt-5;
        .col {
            @extend .col-12;
            @extend .col-md-6;
            @extend .col-lg-4;
            @extend .d-flex;
            .card {
                @extend .w-100;
                @extend .mb-2;
                img {
                    @extend .img-fluid;
                    width: 350px;
                    @include media-breakpoint-down(sm) {
                        width: 510px;
                    }
                }
            }
        }
    }
}
.list-group-item.active {
    background-color: $brand-color;
    border-color: $brand-color;
}
ul.bullet-orange {
    @extend .list-unstyled;
    li:before {
        content: "\2022";
        color: $primary-color;
        font-weight: bold;
        display: inline-block; 
        width: 1em;
        margin-left: 0.5rem;
    }
}
/* process */
#process {
    .container {
        .row {
            .ruler {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 60%;
                height: 0;
                border-bottom-width: 1px;
                border-bottom-style: dashed;
                border-bottom-color: grey;
                transform: translate(-50%, -50%);
                @include media-breakpoint-down(sm) {
                    width: 0;
                    height: 60%;
                    border-bottom-width: 0;
                    border-right-width: 1px;
                    border-right-style: dashed;
                    border-right-color: grey;
                }
            }
            ul.stepper {
                li {
                    a {
                        width: 100%;
                        h4.title {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 65%;
                            color: #fff;
                            margin: 0;
                            transform: translate(-50%,-50%);
                        }
                        span.circle {
                            position: absolute;
                            top: 20%;
                            left: 78%;
                            width: 60px;
                            height: 60px;
                            line-height: 60px;
                            transform: translate(-50%,-50%);
                        }
                    }
                }
            }
            @media (max-width: 47.9375rem) {
                .stepper-horizontal li:not(:last-child):after {
                    width: 0;
                }
            }
        }
    }
}
/* testimonial */
#kunder {
    @extend .pb-5;
    .testimonial {
        padding: 0 5px;
        .card {
            @extend .card;
            @extend .h-100;
            @extend .text-center;
            @extend .text-dark;
            background-color: rgba(255,255,255,0.8);
        }
    }
}

/* buttons */
.btn {
    @extend .text-uppercase;
}
a.expandable-btn {
    @extend .btn-floating;
    @extend .btn-primary;
    @include media-breakpoint-up(sm) {
        display: none;
    }
    position: absolute;
    right: 5;
    top: 5;
    &[aria-expanded=false] {
        i.fa-minus {
            display: none;
        }
    }
    &[aria-expanded=true] {
        i.fa-plus {
            display: none;
        }
    }
}
/* stiped dl list */
dl.striped {
    dd:nth-child(4n-2), dt:nth-child(4n-3) {
        background-color: rgba(0, 0, 0, 0.1);
    }
    @include media-breakpoint-up(md) {
        dt {
            margin-bottom: 8px;
        }
    }
}
/* openhours */
table.openhours {
    @extend .table;
    @extend .text-center;
    tr:first-of-type {
        td {
            border: 0;
        }
    }
    td {
        padding: 0.5rem;
        font-size: 1rem;
    }
}
.contact-informations {
    .openhours {
        @extend .d-flex;
        @extend .justify-content-between;
    }
}

/* clickable */
.clickable {
    cursor: pointer;
}

section.kopbil {
    @extend .my-5;
    @extend .grey-text;
    i, h5, h2 {
        @extend .black-text;
    }
    h5 {
        @extend .font-weight-bold;
        @extend .mb-3;
    }
    i {
        font-size: 2em;
        @include media-breakpoint-up(xl) {
            font-size: 3em;
        }
        &:before {
            color: $brand-color;
        }
    }
}


@import "custom/breadcrumb";
@import "custom/carousel";
@import "custom/cars/all";
@import "custom/fancybox";
@import "custom/footer";
@import "custom/forms";
@import "custom/garantier";
@import "custom/header";
@import "custom/kontakt";
@import "custom/navbar";
@import "custom/partners";
@import "custom/sidebar";
@import "custom/slickslides";
@import "custom/spechours";
@import "custom/tabs";
@import "custom/trygghet";