@import "custom/flexbox";

// Your custom variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1366px,
  xxxl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px
);
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100;300;400;500&family=Oswald:wght@400;700&display=swap');

//$font-family-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, "Catamaran", Roboto, "Helvetica Neue", Arial, sans-serif;
//$font-family-serif:      Georgia, "Times New Roman", Times, serif;

$headings-font-family   : 'Oswald', sans-serif;
$catamaran-font-family  : 'Catamaran', sans-serif;

$primary-color          : rgb(240, 131, 61);
$theme-colors: (
  "primary"             : $primary-color
);
$brand-color            : $primary-color;

$mdb-font-family        : $catamaran-font-family !default;
