body {
    .navbar.navbar-light {
        @extend .d-print-none;
        @extend .text-uppercase;
        font-weight: 700;
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: grey;
                }
                &.active, &:hover {
                    .nav-link {
                        color: $brand-color;
                        background: none;
                    }
                }
            }
        }
        .navbar-brand {
            max-width: 130px;
            margin-right: 60px;
            img {
                @extend .img-fluid ;
                -webkit-transition: all .5s; /* For Safari 3.1 to 6.0 */
                transition: all .5s;
            }
        }
        &.scrolled {
            .navbar-brand {
                margin-right: 120px;
                max-width: 70px;
                max-height: 70px;
            }
        }
        @include media-breakpoint-down(md) {
            background-color: #ffffff;
            img {
                max-height: 50px;
            }
        }
        
        .btn-group {
            .dropdown-menu {
                a:hover {
                    color: #000 !important;
                }
                a:active {
                    color: #fff !important;
                }
            }
        }
        -webkit-transition: height .5s; /* For Safari 3.1 to 6.0 */
        transition: height .5s;
    }
}