// Your custom skin
// Skins
$skins: () !default;
$skins: map-merge(
    (
        "bilaffaren": (
            "skin-primary-color": $primary-color,
            "skin-navbar": #fff,
            "skin-footer-color": #292929,
            "skin-accent": #000000,
            "skin-flat": #F9F9F9,
            "skin-sidenav-item": $primary-color,
            "skin-sidenav-item-hover": rgba(0,0,0,0.05),
            "skin-gradient-start": #ababab,
            "skin-gradient-end": #fff,
            "skin-mask-slight": rgba(240, 131, 61, 0.5),
            "skin-mask-light": rgba(240, 131, 61, 0.65),
            "skin-mask-strong": rgba(240, 131, 61, 0.8),
            "skin-sn-child": #4285F4,
            "skin-btn-primary": $primary-color,
            "skin-btn-secondary": #999999,
            "skin-btn-default": #000000,
            "skin-text": #333333
        )
    ),
    $skins
);

