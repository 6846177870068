section.team-section {
    a.icons-sm {
        font-size: 1rem;
        color: black;
    }
    .role {
        @extend .h6;
        @extend .grey-text;
        @extend .font-weight-bold;
        @extend .mb-3;
    }
}
.sidebar {
    .contact-informations.main {
        @extend .h5;
    }
}
ul.contact-informations {
    @extend .list-unstyled;
    li+li {
      @extend .mt-2;
    }
    .openhours {
      max-width: 250px;
    }
}
