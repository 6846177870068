form {
    .form-group {
        @extend .md-form;
        p.help-block.form-field-error-label {
            @extend .small;
            @extend .red-text;
        }
        .g-recaptcha {
            > div {
                margin: 0 auto;
            }
        }
    }
    input[type=submit] {
        @extend .btn;
        @extend .btn-primary;
    }
    &.saljdinbil {
        .md-form.md-outline {
            textarea.md-textarea {
                padding: 5px;
                & + label {
                    top: 0;
                }
            }
            input {
                max-width: 300px;
            }
            input, textarea.md-textarea {
                background-color: white;
                &:focus:not([readonly]) {
                    border-color: $primary-color;
                    box-shadow: inset 0 0 0 1px $primary-color;
                    & + label {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}
/* modal form */
.modal-content {
    .md-form {
        margin-top: 2rem;
    }
}
