/* bilsidan */
section#bil {
    .slider {
        @include media-breakpoint-down(sm) {
            padding: 0 5px;
        }
    }
    .buttonblock {
        @extend .d-print-none;
        .btn {
            @extend .btn-block;
            @extend .btn-primary;
            margin-top: 0.5rem;
            i {
                @extend .mr-2;
            }
        }
    }
    .objekt-parameters {
        @extend .col-12;
        @extend .col-lg-4;
        @media print {
            @include make-col(5);
        }
        a {
            color: $brand-color;
        }
    }
    #objekt-utr {
        @extend .col-12;
        @extend .col-lg-8;
        .objekt-utr {
            font-size: 0.9rem;
            ul {
                display: block;
                column-count: 1;
                @include media-breakpoint-up(sm) {
                    column-count: 2;
                }
                @include media-breakpoint-up(md) {
                    column-count: 3;
                }
                li {
                    line-height: 1.5rem;
                    i {
                        margin-right: 3px;
                    }
                }
            }
            @media print {
                display: block;
                ul {
                    li.list-item {
                        @include make-col(4);
                    }
                }
            }
        }
    }
}
