/*--Spechours section------------------------*/
#spechours {
    color: #ffffff;
    text-shadow: 2px 2px 2px #000;
    .container, .container-fluid {
        @extend .red;
        @extend .darken-4;
        padding: 30px 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 300px;
        img {
            max-width: 100%;
        }
        div.center-block {
            ul {
                @extend .rgba-black-slight;
                @extend .z-depth-2;
                @extend .list-unstyled;
                @extend .p-4;
                @extend .mt-4;
                font-size: 1rem;
                text-shadow:none;
                margin: 0 auto;
                max-width: 100%;
                width: 300px;
                li {
                    span {
                        float: right;
                    }
                }
            }
        }
    }
    &.easter {
        .container, .container-fluid {
            @extend .yellow;
            background-size: cover;
            div.center-block {
                ul {
                    @extend .rgba-yellow-strong;
                    @extend .black-text;
                }
            }
        }
    }
}
.snowflakewrapper {
    position: relative;
    margin: 0 auto;
    width: 980px;
    #snowflakeContainer {
        position: absolute;
        left: 0px;
        top: 0px;
        .snowflake {
            padding-left: 15px;
            font-family: Cambria, Georgia, serif;
            font-size: 14px;
            line-height: 24px;
            position: fixed;
            color: #FFFFFF;
            user-select: none;
            z-index: 1000;
            &:hover {
                cursor: default;
            }
        }
    }
}
