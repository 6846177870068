$slick-font-family: "Font Awesome 5 Free";
$slick-prev-character: fa-content($fa-var-chevron-left);
$slick-next-character: fa-content($fa-var-chevron-right);
$slick-dot-character: "\f111";
$slick-loader-path: "../img/overlays/";
$slick-arrow-color: $primary-color;
$slick-dot-color: $primary-color;
$slick-dot-color-active: $primary-color('darken-4');
@import "../node_modules/slick-carousel/slick/slick.scss";
@import "../node_modules/slick-carousel/slick/slick-theme.scss";


// section#bil {
//     @include media-breakpoint-up(lg) {
//         .price-overlay {
//             right: 58px; // if max-width 1024 on slick-slide
//         }
//     }
// }
.slick-slide {height: auto!important;}
.slick-dots {
    li {
        button {
            &:before {
                @extend %fa-icon;
                @extend .fas;
            }
        }
    }
}
.multi-testimonial {
    .slick-track {
        display: flex!important;
    }
    .slick-slide {
        height: inherit!important;
    }
    .slick-dots {
        li {
            button {
                &:before {
                    font-size: 15px;
                }
            }
        }
    }
    button {
        &.slick-prev, &.slick-next {
            &:before {
                @extend %fa-icon;
                @extend .fas;
            }
        }
    }
}
.slider-for {
    .slick-slide {
        .image {
            width: 100%;
            padding-bottom: 66.5%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        a {
            .slider-fullscreen {
                position: absolute;
                right: 0;
                bottom: 0;
                background: rgb(51, 51, 51);
                opacity: 0.7;
                padding: 5px 10px;
                color: white;
                z-index: 1000;
            }
        }
    }
}
.slider {
    .slick-list {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
}
.slidernav {
    .slick-slide {
        position: relative;
        max-width: 100%;
        height: auto!important;
        margin: 0px;
        .image {
            background-size: cover;
            width: 100%;
            padding-bottom: 66.5%;
            background-position: center;
            background-repeat: no-repeat;
        }
        opacity: $slick-opacity-default;
        &.slick-current {
            opacity: $slick-opacity-on-hover;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
            .image:before {
                background: $primary-color;
                height: 4px;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                position: absolute;
            }
        }
    }
}
.slick-slider {
    a.prev, a.next {
        position: absolute;
        top: 0;
        bottom: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 15%;
        color: $primary-color;
        text-align: center;
        opacity: .5;
        cursor: pointer;
        text-decoration: none;
        transition: all .2s ease-in-out;
        z-index: 100;
        i:before, i:before {
          font-size: 3rem;
        }
        &:hover {
          color: $primary-color;
          opacity: 1;
          text-shadow: 2px 2px 2px #000;
          transition: all .2s ease-in-out;
        }
    }
    a.prev {
        left:0;
    }
    a.next {
        right: 0;
    }
    .slick-dots {
        li button:before {
            color: $primary-color;
        }
    }
}
section#bil {
    .slick-dots {
        bottom: 0;
        @include media-breakpoint-down(sm) {
            bottom: -65px;
            height: 60px;
        }
    }
}
section.bilar.slick-slider {
    .slick-prev:before, .slick-next:before {
        color: grey;
    }
    .slick-slide {
        margin: 5px;
        a {
            display: block;
            box-shadow: 0px 0px 2px #ccc;
            color: #000;
            .carimage {
                width: 100%;
                padding-bottom: 70%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
                overflow: hidden;
                .carplace {
                    background: $brand-color;
                    color: rgb(255, 255, 255);
                    padding: 0;
                    text-align: center;
                    font-weight: 600;
                    position: absolute;
                    text-transform: uppercase;
                    font-weight: bold;
                    width: 220px;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    top: 35px;
                    left: -65px;
                }
            }
            h4 {
                text-align: center;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 2rem;
                line-height: 2rem;
                white-space: nowrap!important;
            }
            .carinfo {
                padding: 10px;
                .btn, .price {
                    margin-top: 10px;
                }
                .price {
                    font-size: 80%;
                    padding: 5px;
                    border: 1px solid rgb(204, 204, 204);
                    border-radius: 5px;
                }
                .price span {
                    font-weight: bold;
                    font-size: 1rem;
                }
                i {
                    font-size: 1rem;
                    .fa-info-circle {
                        position: relative;
                        color: grey;
                        left: 5px;
                        margin-left: 20px;
                    }
                    .fa-arrow-down {
                        color: red;
                        margin-right: 20px;
                    }
                }
                .dl-horizontal {
                    margin-bottom: 10px;
                    width: 80%;
                    margin: auto;
                }
                dt, dd {
                    width: 50% !important;
                    float: left;
                    margin: 0;
                }
                dl dt {
                    text-align: left;
                }
                dl dd {
                    text-align: right;
                }
            }
        }
    }
}
