/* Carousel*/
header {
    #carousel-header-fp.carousel {
        .carousel-inner {
            height: 50vh;
            @include media-breakpoint-down(md) {
                height: 70vh;
            }
            .carousel-item {
                @extend .h-100;
                .view {
                    @extend .h-100;
                    background-size: cover;
                    background-repeat: no-repeat;
                    .mask {
                        > div {
                            @extend .text-center;
                            @extend .mx-5;
                            @extend .text-white;
                        }
                        h1, h4 {
                            text-shadow: 2px 2px 4px black;
                        }
                        ul {
                            max-width: 80%;
                            text-shadow: 2px 2px 3px #000;
                        }
                        .title {
                            @extend .text-uppercase;
                            font-size: 4rem;
                            font-weight: 700;
                        }
                        .btn {
                            @extend .btn-lg;
                            .fa {
                                /* font-size: 2rem; */
                                margin-right: 10px;
                            }
                        } 
                    }
                }
                &:nth-child(1) {
                    .view {
                        background-position: center;
                    }
                }
                &:nth-child(2) {
                    .view {
                        background-position: 100% 70%;
                        @include media-breakpoint-up(md) {
                            background-position: 100% 100%;
                        }
                    }
                }
                &:nth-child(3) {
                    .view {
                        background-position: 100% 70%;
                        background-size: contain;
                        @include media-breakpoint-up(md) {
                            background-position: 100% 100%;
                        }
                    }
                }
            }
        }
    }
}
#footer-slideshow {
    .carousel {
        border-bottom: 2px solid $primary-color;
        ol.carousel-indicators {
            li {
                background-color: $primary-color;
            }
        }
        .carousel-item {
            .view {
                min-height: 450px;
            }
            .mask {
                background-color: #292929;
                width: 50%;
                overflow: visible;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                .elipse {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                    position: absolute;
                    height: 100%;
                    right: 0;
                    top: 0;
                    svg {
                        width: auto;
                        height: 100%;
                        vertical-align: middle;
                        position: relative;
                        left: 100%;
                        left: calc(100% - 2px);
                    }
                }
            }
            .carousel-caption {
                bottom: 50%;
                transform: translateY(50%);
                @include media-breakpoint-up(lg) {
                    text-align:left;
                    padding-right: 40%;
                }
                h2 {
                    color: white;
                }
            }
        }
    }
}
