/* Sidebar */
#sidebar {
    @include make-col(4);
    @extend .d-none;
    @extend .d-lg-block;
}
.sidebar {
    @extend .my-3;
    img {
        @extend .img-fluid;
    }
    ul.contact-informations {
        @extend .list-unstyled;
    }
}
