
footer.page-footer {
    margin-top: 0;
    .footer-logo img {
        width: 100%;
        max-width: 200px;
    }

    .footer-copyright {
        position: relative;
        overflow: hidden;
        height: 50px;
        color: rgba(255,255,255,0.6);
        background-color: rgba(0,0,0,0.2);
        text-align: center;
        @include media-breakpoint-down(sm) {
            height: 95px;
        }
        .bybiloit {
            @include media-breakpoint-up(md) {
                position:absolute;
                right:10px;
                bottom: 0;
            }
            @include media-breakpoint-down(sm) {
                line-height: normal;
                display: block;
                text-align: center;
            }
            a {
                @extend .small;
                color: rgba($white, .6);
                padding: 10px;
            }
        }
    }
    .container {
        .row {
            @include justify-content(space-around);
            @include flex-wrap(wrap);

            @include media-breakpoint-down(md) {
                > div {
                    @include make-col-ready();
                    @include make-col(6);
                    margin-bottom: 20px;
                }
            }
            @include media-breakpoint-down(sm) {
                > div {
                    @include make-col-ready();
                    @include make-col(9);
                }
            }

            table.openhours {
                @extend .white-text;
                max-width: 300px;
                @include media-breakpoint-down(sm) {
                    margin: 0 auto;
                }
                tr:first-of-type {
                    td {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
