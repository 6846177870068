/* garantier */
.mrf, .autoconcept {
    @extend .row;
    div {
        @extend .col;
        @include make-col(3);
        @include media-breakpoint-down(md) {
            @include make-col(6);
        }
        @extend .mb-3;
        img {
            @extend .img-fluid;
        }
    }
}
.sidebar {
    .autoconcept {
        div {
            @include make-col(6);
        }
    }
}
.garantiversion {
    font-size: 0.5rem;
    font-style: italic;
    margin: 0 0 15px 0;
}
ul.garantier {
    list-style-type: decimal;
}
