header {
    .view {
        background-position: center;
        background-size: cover;
    }
}
.intro {
    height: 305px;
    .view {
        @extend .h-100;
        text-shadow: 2px 2px 3px #000;
        > div {
            @extend .h-100;
        }
    }
}
